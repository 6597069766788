import React from 'react';
import Script from 'react-load-script'
import Measure from 'react-measure'
import * as THREE from 'three'
import './index.css';

/* global Autodesk*/

class ForgeViewer extends React.Component {

    constructor(props) {
        super(props);
        this.docs = [];
        this.views = {};
        this.state = {enable: false, error: false, empty: true};
        this.viewerDiv = React.createRef();
        this.viewer = null;
        this.resizeHandling = null;
        this.runsData = {};
        this.selectionProcessed = false;

        //if urn already given when component is created
        if (typeof props.urn !== 'undefined' && props.urn !== '')
            this.docs.push(props.urn);
    }

    /**
     * Previous destroying the viewer component we close the connection with Forge
     */
    componentWillUnmount() {
        if (this.viewer) {
            this.viewer.tearDown()
            this.viewer.finish()
            this.viewer = null
        }
    }

    /**
     * Once model has loaded we set the model as loaded and if is set prespectiveCamera property in true
     * We switch to perspectiveCamera and update the camera to make effective the changes
     * @param model
     */
    handleLoadModelSuccess(model) {
        if (this.props.onModelLoad)
            this.props.onModelLoad(this.viewer, model);
        if (this.props.perspectiveCamera) {
            //Switch to perspective (view or real world as opposed to orthography) camera
            this.viewer.navigation.toPerspective();
            this.viewer.navigation.updateCamera();
        }
    }

    /**
     * On Error when loading model we execute the method provided by the parent component
     * @param errorCode
     */
    handleLoadModelError(errorCode) {
        this.setState({error: true});
        if (this.props.onModelError)
            this.props.onModelError(errorCode);
    }

    /**
     * On Error when loading viewer we execute the method provided by the parent component
     * @param errorCode
     */
    handleViewerError(errorCode) {
        this.setState({error: true});
        if (this.props.onViewerError)
            this.props.onViewerError(errorCode);
    }

    /**
     * handleScriptLoad is on charge of init the Forge viewer
     */
    handleScriptLoad() {
        let options = {
            env: 'AutodeskProduction',
            getAccessToken: this.props.onTokenRequest,
        };

        Autodesk.Viewing.Initializer(
            options, this.handleViewerInit.bind(this));
    }

    /**
     * Isolates by given a list of element ids defined by the parent component
     */
    isolateByList() {
        this.viewer.isolate(this.props.listToIsolate)
        this.viewer.fitToView(this.props.listToIsolate)
    }

    /**
     * Cleans the current isolated elements
     */
    cleanIsolated() {
        this.selectionProcessed = false;
        this.viewer.isolate([])
        this.viewer.fitToView([])
    }

    /**
     * This method is called every time an element is selected
     * it has a setting to avoid an infinite loop on the option for isolate systems
     * if is set onSelectIsolateSystem as true the select action will also select the list of elements
     * provided by onRequestSystem (method implemented in the parent component)
     * @param event
     */
    onSelectionChanged(event) {
        if (!this.selectionProcessed) {
            const currSelection = this.viewer.getSelection();
            this.cleanIsolated()
            this.viewer.fitToView(currSelection);
            if (this.props.onSelectIsolateSystem && !this.selectionProcessed) {
                this.selectionProcessed = true;
                this.props.onRequestSystem(currSelection)
                    .then((reqSystem) => {
                        const elementsToSelect = [currSelection[0], ...reqSystem];
                        this.viewer.isolate(elementsToSelect);
                        this.viewer.fitToView(elementsToSelect);
                    });
            }
        }
    }

    /**
     * Forge Viewer has finished initializing.
     * We add listeners to SELECTION_CHANGED_EVENT and GEOMETRY_LOADED_EVENT
     */
    handleViewerInit() {
        if (this.props.proxy) {
            Autodesk.Viewing.endpoint.setEndpointAndApi(this.props.proxy, 'derivativeV2')
        }

        let container = this.viewerDiv.current;

        // Create Viewer instance so we can load models.
        if (this.props.headless) {
            this.viewer = new Autodesk.Viewing.Viewer3D(container);
        } else {
            this.viewer = new Autodesk.Viewing.Private.GuiViewer3D(container);
            this.viewer.setTheme('light-theme');

        }

        const selectionChanged = this.onSelectionChanged.bind(this)
        const isolatedByList = this.isolateByList.bind(this)
        this.viewer.addEventListener(Autodesk.Viewing.SELECTION_CHANGED_EVENT, selectionChanged);
        this.viewer.addEventListener(Autodesk.Viewing.GEOMETRY_LOADED_EVENT, isolatedByList);

        const errorCode = this.viewer.start();
        if (!errorCode) {
            this.setState({enable: true});
            this.reviewDocuments();
        } else {
            console.error('Error starting Forge Viewer - code:', errorCode);
            this.handleViewerError(errorCode);
        }
    }

    /**
     * Forge viewer has successfully loaded a given document
     * sets augment viewables with the doc they came from
     * sets camera quality low
     * raise an event so caller can select a viewable to display
     * @param doc
     */
    handleLoadDocumentSuccess(doc) {

        let views = Autodesk.Viewing.Document.getSubItemsWithProperties(
            doc.getRootItem(), {'type': 'geometry'}, true
        );

        //augment viewables with the doc they came from
        views.forEach(viewable => {
            viewable.doc = doc;
        });
        //sets camera quality low
        if (this.props.qualityLow) {
            this.viewer.setQualityLevel(false, false);
            this.viewer.setGroundShadowAlpha(0);
        }
        //raise an event so caller can select a viewable to display
        if (this.props.onDocumentLoad) {
            this.props.onDocumentLoad(doc, views);

        }
    }

    /**
     * Error loading Forge document
     * @param errorCode
     */
    handleLoadDocumentError(errorCode) {
        this.setState({error: true});
        console.error('Error loading Forge document - errorCode:' + errorCode);
        if (this.props.onDocumentError)
            this.props.onDocumentError(errorCode);
    }

    /**
     * Resets errors
     */
    clearErrors() {
        this.setState({error: false});
    }

    /**
     * Clearing all views for lack of ability to unload models
     */
    clearViews() {
        this.views = {};
        if (this.viewer) {
            //restart viewer, for lack of ability to unload models
            this.viewer.tearDown();
            this.viewer.start();
        }
    }

    /**
     * Cleans errors and reload docs
     */
    reviewDocuments() {
        if (this.viewer) {
            this.clearErrors();
            this.setState({empty: (this.docs.length === 0)});
            this.docs.forEach(urn => {
                this.loadDocument(urn);
            });
        }
    }

    /**
     * Loads a given document
     * @param urn
     */
    loadDocument(urn) {
        let documentId = `urn:${urn}`;
        let successHandler = this.handleLoadDocumentSuccess.bind(this);
        let errorHandler = this.handleLoadDocumentError.bind(this);

        Autodesk.Viewing.Document.load(
            documentId, successHandler, errorHandler
        );
    }

    /**
     * Loads a given view and the specified model
     * @param view
     */
    loadView(view) {
        this.views[view.viewableID] = view;

        let svfUrl = view.doc.getViewablePath(view);
        let successHandler = this.handleLoadModelSuccess.bind(this);
        let errorHandler = this.handleLoadModelError.bind(this);
        let modelOptions = {
            sharedPropertyDbPath: view.doc.getPropertyDbPath()
        };

        //load the specified model
        this.viewer.loadModel(
            svfUrl, modelOptions, successHandler, errorHandler
        );
    }

    /**
     * Helper function to validates equal arrays
     * @param current
     * @param next
     * @returns {boolean}
     */
    isArrayDifferent(current, next) {
        if (current == null && next == null)
            return false;
        else if (current == null || next == null)
            return true;
        else if (current.length !== next.length)
            return true;

        for (let i = 0; i < current.length; i++)
            if (current[i] !== next[i])
                return true;
        return false;
    }

    /**
     * Updates urn validating first the next urn
     * @param nextProps
     * @param nextState
     */
    shouldComponentUpdateURN(nextProps, nextState) {
        //new urn is null, empty or empty array
        if (!nextProps.urn || nextProps.urn === '' || typeof nextProps.urn === 'undefined' ||
            (Array.isArray(nextProps.urn) && nextProps.urn.length === 0)) {
            //clear out views if any document was previously loaded
            if (this.docs.length > 0) {
                this.setDocuments([]);
            }
        } else if (Array.isArray(nextProps.urn)) {
            //always have to check array because equivalence is per element
            if (this.isArrayDifferent(this.props.urn, nextProps.urn)) {
                this.setDocuments(nextProps.urn);
            }
        } else if (nextProps.urn !== this.props.urn) {
            this.setDocuments([nextProps.urn]);
        }
    }

    /**
     * Updates view validating first the next view
     * @param nextProps
     * @param nextState
     */
    shouldComponentUpdateView(nextProps, nextState) {
        //the view property is empty, undefined, or empty array
        if (!nextProps.view || typeof nextProps.view === 'undefined' ||
            (Array.isArray(nextProps.view) && nextProps.view.length === 0)) {
            if (Object.keys(this.views).length > 0)
                this.clearViews();
        } else if (Array.isArray(nextProps.view)) {
            if (this.isArrayDifferent(this.props.view, nextProps.view)) {
                this.setViews(nextProps.view);
            }
        } else if (this.props.view !== nextProps.view) {
            this.setViews([nextProps.view]);
        }
    }

    /**
     * Updates the component validating first the next view and urn
     * @param nextProps
     * @param nextState
     * @returns {boolean}
     */
    shouldComponentUpdate(nextProps, nextState) {
        this.shouldComponentUpdateURN(nextProps, nextState);
        this.shouldComponentUpdateView(nextProps, nextState);
        return true;
    }

    /**
     * Sets a list of documents and view them first
     * @param list
     */
    setDocuments(list) {
        this.docs = list;
        this.clearViews();
        this.reviewDocuments(); //defer loading until viewer ready
    }

    /**
     * Sets a list of views validate them first
     * @param list
     */
    setViews(list) {
        //check to see if views were added or removed from existing list
        let existing = Object.assign({}, this.views);
        let incremental = [];
        list.forEach(view => {
            if (existing.hasOwnProperty(view.viewableID))
            //the view was previously in the list
                delete existing[view.viewableID];
            else {
                //the view is newly added to the list
                incremental.push(view);
            }
        });

        //anything left in old's keys should be unloaded
        let keys = Object.keys(existing);
        if (keys.length > 0) {
            //views were removed, so restart viewer for lack of 'unload'
            this.viewer.tearDown();
            this.viewer.start();
            list.forEach(view => {
                this.loadView(view);
            });
        } else {
            //load views incrementally rather than a complete teardown
            incremental.forEach(view => {
                this.loadView(view);
            });
        }
    }

    /**
     * Method called when viewer releases hide or unhide actions
     * @returns {boolean}
     */
    onRelease() {
        const viewer = this.viewer.getCurrentViewer();
        const selection = viewer.getSelection();
        if (selection.length === 0) {
            viewer.showAll();
        }
        else {
            viewer.hide(selection);
        }
        return true;
    }

    /**
     * Isolates or remove the current isolation
     * @constructor
     */
    IsolateHotKey() {
        this.viewer.myCurrentViewer._hotkeyManager.pushHotkeys('HideUnhide', [
            {
                keycodes: [Autodesk.Viewing.KeyCode.h],
                onRelease: this.onRelease
            }
        ]);
    }

    /**
     * Method called un viewer resize
     * @param rect
     */
    handleResize(rect) {
        //cancel any previous handlers that were dispatched
        if (this.resizeHandling)
            clearTimeout(this.resizeHandling)

        //defer handling until resizing stops
        this.resizeHandling = setTimeout(() => {
            if (this.viewer) this.viewer.resize()
        }, 100)
    }

    /**
     * Renders the ForgeViewer component
     * @returns {*}
     */
    render() {
        const version = (this.props.version) ? this.props.version : "6.0";

        return (
            <Measure bounds onResize={this.handleResize.bind(this)}>
                {({measureRef}) =>
                    <div ref={measureRef} className="ForgeViewer">
                        <div ref={this.viewerDiv}></div>
                        <link rel="stylesheet" type="text/css"
                              href={`https://developer.api.autodesk.com/modelderivative/v2/viewers/style.min.css?v=v${version}`}/>
                        <Script
                            url={`https://developer.api.autodesk.com/modelderivative/v2/viewers/viewer3D.min.js?v=v${version}`}
                            onLoad={this.handleScriptLoad.bind(this)}
                            onError={this.handleViewerError.bind(this)}
                        />

                        {this.state.empty &&
                        <div className="scrim">
                            <div className="message">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                     fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                     strokeLinejoin="round">
                                    <path
                                        d="M12.89 1.45l8 4A2 2 0 0 1 22 7.24v9.53a2 2 0 0 1-1.11 1.79l-8 4a2 2 0 0 1-1.79 0l-8-4a2 2 0 0 1-1.1-1.8V7.24a2 2 0 0 1 1.11-1.79l8-4a2 2 0 0 1 1.78 0z"></path>
                                    <polyline points="2.32 6.16 12 11 21.68 6.16"></polyline>
                                    <line x1="12" y1="22.76" x2="12" y2="11"></line>
                                </svg>
                            </div>
                        </div>
                        }

                        {this.state.error &&
                        <div className="scrim">
                            <div className="message">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                     fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                     strokeLinejoin="round">
                                    <circle cx="12" cy="12" r="10"></circle>
                                    <line x1="12" y1="8" x2="12" y2="12"></line>
                                    <line x1="12" y1="16" x2="12" y2="16"></line>
                                </svg>
                                <div>Viewer Error</div>
                            </div>
                        </div>
                        }

                        {!this.state.enable &&
                        <div className="scrim">
                            <div className="message">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                     fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                     strokeLinejoin="round">
                                    <polygon points="13 2 3 14 12 14 11 22 21 10 12 10 13 2"></polygon>
                                </svg>
                                <div>{this.props.startingMessage}</div>
                            </div>
                        </div>
                        }
                    </div>
                }
            </Measure>
        );
    }
}

export default ForgeViewer;
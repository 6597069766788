import React, {Component} from 'react'
import ForgeViewer from './Components/forge-viewer/index'

class App extends Component {
    constructor(props) {
        super(props)
        this.state = {
            view: null
        }
    }


    handleViewerError(error) {
        console.log('Error loading viewer.');
    }

    /**
     * After the viewer loads a document,
     * we need to select which viewable to display in our component
     * @param doc
     * @param viewables
     */
    handleDocumentLoaded(doc, viewables) {
        if (viewables.length === 0) {
            console.error('Document contains no viewables.');
        }
        else {
            //Select the first viewable in the list to use in our viewer component
            this.setState({view: viewables[0]});
        }
    }

    handleDocumentError(viewer, error) {
        console.log('Error loading a document');
    }

    handleModelLoaded(viewer, model) {

        console.log('Loaded model:', model);
    }

    handleModelError(viewer, error) {
        console.log('Error loading the model.');
    }

    getForgeToken() {
        /* TODO: Normally, this would call an endpoint on your server to generate a public
        access token (using your client id and sercret). Doing so should yield a
        response that looks something like the following...
        */
        return {
            access_token:"eyJhbGciOiJIUzI1NiIsImtpZCI6Imp3dF9zeW1tZXRyaWNfa2V5In0.eyJzY29wZSI6WyJ2aWV3YWJsZXM6cmVhZCJdLCJjbGllbnRfaWQiOiJ0UmE0ald6TDhKZlZPSUp3R0hiTEdqSU1DcUcyNFB6SyIsImF1ZCI6Imh0dHBzOi8vYXV0b2Rlc2suY29tL2F1ZC9qd3RleHA2MCIsImp0aSI6Ims4bVM5c2tGSnhXRmRKblF5b043Q3RrQ2VUTEZoUzZCR3h1N3o0VXdQeGh2ZXlOcXAwS2JndUNaclZEQU90QkYiLCJleHAiOjE1ODg3OTcwMTh9.SNkp8sefQJze7IuQ-U8G51cxoQTgu8KJi2F7mw1v074",
            expires_in: 3599,
            token_type: "Bearer"
        };
    }

    /**
     *  Once the viewer has initialized, it will ask us for a forge token so it can
     access the specified document.
     * @param onAccessToken
     */

    handleTokenRequested(onAccessToken) {
        if (onAccessToken) {
            let token = this.getForgeToken();
            if (token)
                onAccessToken(
                    token.access_token, token.expires_in);
        }
    }

    /**
     * Executed when an element is selected in viewer and onSelectIsolateSystem = true
     * Retrieves elements in system to be selected
     * @param elementId
     */
    async handleRequestSystem(elementId){
        console.log('System elements will be retrieve:', elementId);
        return [3931, 4818, 4820];
    }

    render() {
        return (
            <div className="App">
                <ForgeViewer
                    version="6.0"
                    urn="dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6dHJhNGp3emw4amZ2b2lqd2doYmxnamltY3FnMjRwemtfdHV0b3JpYWxfYnVja2V0L0RlbW8lMjBGQi1HRENDJTIwTW9kZWwucnZ0"
                    view={this.state.view}
                    qualityLow={true}
                    startingMessage={'Starting Viewer...'}
                    headless={true}
                    onSelectIsolateSystem={true}
                    perspectiveCamera={true}
                    listToIsolate={[]}
                    onViewerError={this.handleViewerError.bind(this)}
                    onTokenRequest={this.handleTokenRequested.bind(this)}
                    onDocumentLoad={this.handleDocumentLoaded.bind(this)}
                    onDocumentError={this.handleDocumentError.bind(this)}
                    onModelLoad={this.handleModelLoaded.bind(this)}
                    onModelError={this.handleModelError.bind(this)}
                    onRequestSystem={this.handleRequestSystem.bind(this)}
                />
            </div>
        )
    }
}

export default App
//https://docs.b360.autodesk.com/projects/6f114eb1-69fc-47b9-b8fa-d615a871be96/folders/urn:adsk.wipprod:fs.folder:co.Ws6R9bIeQLu7dqZJ-z6SAw/detail/viewer/items/urn:adsk.wipprod:dm.lineage:tpCyEEAlSOW8V4iLTs07YQ